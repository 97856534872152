<template>
    <b-row>
        <b-col col="12" md="3">
            <b-card>
                <h6>Choose containers</h6>
            </b-card>
        </b-col>
        <b-col col="12" md="9">
            <b-card>
                dsfsdf
            </b-card>
        </b-col>
    </b-row>
</template>

<script>
import {BRow, BCol, BCard} from "bootstrap-vue";
export default {
    components:{
        BRow,
        BCol,
        BCard,
    }
}
</script>